import "..//pages/allCSS.css";
import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase.js";
import { doc, getDoc } from "firebase/firestore";
import { useCart } from "./cartContext.js";
import { useNavigate } from "react-router-dom";
import { loadGtag } from "..//components/gtag.js";
import ToggleSwitchPrice from "../components/toggleprice.js";

const ServiceOffers = () => {
  const [showThanks, setShowThanks] = useState(false);
  const [isMonthly, setIsMonthly] = useState(true);
  const { addToCart } = useCart();
  const [serviceDetails, setServiceDetails] = useState([]);
  const navigate = useNavigate();

  const handleCheckout = () => {
    // Check if gtag is loaded and defined
    if (typeof window.gtag === "function") {
      // Send the apply event to Google Analytics
      window.gtag("event", "productSale", {
        value: 1,
      });
    } else {
      console.error("gtag is not defined or not loaded yet");
    }

    // Show the "Thanks" message instead of navigating right away
    setShowThanks(true);
  };

  const handleThanksButtonClick = () => {
    // Navigate to the home page after clicking the "Thanks" button
    navigate("/search");
  };

  const [isGtagLoaded, setIsGtagLoaded] = useState(false);

  useEffect(() => {
    // Load gtag when the component mounts and set the state when it's ready
    loadGtag()
      .then(() => {
        setIsGtagLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading gtag:", error);
      });
  }, []);


  const sendContactEmail = async () => {
    if (isGtagLoaded && typeof window.gtag === 'function') {
      // Send the signup event to Google Analytics
      window.gtag('event', 'apply', {
        value: 1,
      });
    } else {
      console.error('gtag is not defined or not loaded yet');
    }

    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated.');
      return;
    }

    const userEmail = user.email;

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/contactus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sender_email: userEmail, incentive_id: 'Service Interest' }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);

        // Show the "Thanks" message after successful submission
        setShowThanks(true);
      } else {
        const error = await response.json();
        console.error('Error sending email:', error.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  // Handler for toggle switch changes
  const handleToggleChange = () => {
    setIsMonthly((prev) => !prev); // Toggle between true and false
  };


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const serviceIds = ["jQnbH5O53pBykJ6vFI9U", "0UuzYZolWjBtFqHCQqNt"];
        const services = await Promise.all(
          serviceIds.map(async (id) => {
            const docRef = doc(db, "services", id);
            const docSnap = await getDoc(docRef);
            return { id, ...docSnap.data() };
          })
        );
        setServiceDetails(services);
      } catch (error) {
        console.error("Error fetching service details: ", error);
      }
    };
    fetchServices();
  }, []);

  const handleAddToCart = (service) => {
    const selectedService = {
      ...service,
      selectedPrice: isMonthly ? service.servicePrice : service.servicePriceAnnual, // Corrected
      isMonthly: isMonthly, // Indicates whether it’s monthly or annual
    };
  
    addToCart(selectedService);
    localStorage.setItem("purchasedService", JSON.stringify(selectedService)); // Store selected service in local storage
    navigate("/cart");
  };
  



  return (
    <div className="subscription-container">
      <header className="subscription-header">
        <title>Abbonamenti per ogni fase</title>
        <h1>Abbonamenti per ogni fase</h1>
        <p>
          Esplora i nostri piani tecnologici, ciascuno progettato per migliorare
          il processo di richiesta di sovvenzioni con software potenti e
          supporto esperto.
        </p>
        <div className="toggle-switch-container-price">
        <ToggleSwitchPrice
          label={isMonthly ? 'Mensile' : 'Annuale (2 mesi gratis)'}
          onChange={handleToggleChange}
          checked={isMonthly} // Pass the current state to the toggle
        />
                </div>
      </header>
      <section className="subscription-piani">

      <section className="subscription-piani">
        {serviceDetails.map((service) => (
          <div key={service.id} className="subscription-piano">
            <h2>{service.serviceName}</h2>
            <p className="subscription-prezzo">
            €{isMonthly ? service.servicePrice : service.servicePriceAnnual} + IVA
            </p>
            <p className="subscription-subject">{service.serviceDescription}</p>
            <ul>
            {service.serviceBenefits.map((benefit, index) => (
              <li key={index} className="subscription-tick">
                <i className="fas fa-check" aria-hidden="true"></i> {benefit}
              </li>
            ))}
          </ul>
            <button onClick={() => handleAddToCart(service)} className="subscription-button">
              Aggiungi al carrello
            </button>
          </div>
        ))}
      </section>

     

       
        <div className="subscription-piano subscription-completo">
          <h2>Oneshot | Addons</h2>
          <p className="subscription-prezzo">
          Ricarica e aggiungi altri prodotti al tuo abbonamento. Tutti i prezzi sono + IVA
          </p>
          <div style={{display: "flex"}}>
          <ul>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> +1 applicazione
            </li>
          </ul>
          <div style={{display: "flex"}}>
          <p className="subscription-prezzo" style={{display: "flex", fontSize:"14px"}}>
          €69.99
          </p>
          <a href="#" className="subscription-button">
            Compra Ora
          </a>
        </div>
        </div>
        </div>
      </section>
      <footer className="subscription-footer">
        <p>
          Unisciti a oltre 2500+ aziende che stanno già crescendo con
          Incentivato.it
        </p>
        <a href="#" className="subscription-button">
          Inizia subito
        </a>
      </footer>
    </div>
  );
};

export default ServiceOffers;
