import { auth, googleProvider, db } from "../config/firebase.js";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendSignInLinkToEmail,
  onAuthStateChanged,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useState, useEffect } from "react";
import {
  doc,
  setDoc,
  addDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from '..//components/auth-email.js';
import "..//pages/allCSS.css"
import { loadGtag } from '..//components/gtag.js';

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRegione, setSelectedRegione] = useState("");
  const [selectedComune, setSelectedComune] = useState("");
  const [atecoL1, setAtecoL1] = useState([]);
  const [selectedAtecoL1, setSelectedAtecoL1] = useState("");
  const [atecoL2, setAtecoL2] = useState("");
  const [website, setWebsite] = useState("");
  const [piva, setPiva] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGtagLoaded, setIsGtagLoaded] = useState(false);
  const [buttonText, setButtonText] = useState('Andiamo!');
  const [showModal, setShowModal] = useState(false); // State for showing the modal
  const [modalMessage, setModalMessage] = useState('Controllando i dettagli');
  const [showSuccess, setShowSuccess] = useState(false); 
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [privacyPolicyError, setPrivacyPolicyError] = useState('');
  const [companySize, setCompanySize] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState(0);
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [companyAteco, setCompanyAteco] = useState("");
  const [regione, setRegione] = useState("");
  const [currentuserID, setCurrentUserID] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [comune, setComune] = useState("");

  const { setUserRole } = useAuth();
  const auth = getAuth();

  useEffect(() => {
    // Load gtag when the component mounts and set the state when it's ready
    loadGtag()
      .then(() => {
        setIsGtagLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading gtag:", error);
      });
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        //console.log("User is signed in, UID:", user.uid);
        // Perform actions after user sign-in (e.g., update UI or redirect)
      } else {
        console.log("User is signed out.");
        // Handle sign-out scenario
      }
    });
  
    return () => unsubscribe(); // Cleanup on unmount
  }, []); 

  const handlePrivacyPolicyChange = (e) => {
    setAcceptPrivacyPolicy(e.target.checked);
    if (e.target.checked) {
      setPrivacyPolicyError('');
    }
  };

  const handleSignUp = async (e) => {
  e.preventDefault();

  // Reset error messages
  setErrorMessage('');
  setPasswordError('');
  setPrivacyPolicyError('');

  // Perform client-side validation first
  if (
    !email ||
    !password ||
    !firstName ||
    !lastName ||
    !piva ||
    !selectedRole ||
    !selectedRegione ||
    !selectedComune
  ) {
    setErrorMessage('Please fill in all required fields.');
    setIsSuccess(false);
    return;
  }

  if (piva.length !== 11 || !/^\d+$/.test(piva)) {
    setErrorMessage('Partita IVA must be exactly 11 digits.');
    setIsSuccess(false);
    return;
  }

  if (password.length < 6) {
    setPasswordError('Password must be at least 6 characters long.');
    setIsSuccess(false);
    return;
  }

  if (!acceptPrivacyPolicy) {
    setPrivacyPolicyError('You must accept the Privacy Policy to proceed.');
    setIsSuccess(false);
    return;
  }

  // Show loading state
  setButtonText('Aspetta un attimo...');
  setIsLoading(true);
  setShowModal(true);
  setModalMessage('Controllando i dettagli...');
  setShowSuccess(false);

  try {
    if (isGtagLoaded && typeof window.gtag === 'function') {
      window.gtag('event', 'signup', { value: 1 });
    } else {
      console.error("gtag is not defined or not loaded yet");
    }

    // Simulate delay
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Firebase Authentication: Create user
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Send email verification
    await sendEmailVerification(user);

    // Store user details in Firestore
    await setDoc(doc(db, 'users', user.uid), {
      email: user.email,
      role: selectedRole,
      service: service,
      piva: piva,
      firstName: firstName,
      lastName: lastName,
      regione: selectedRegione,
      comune: selectedComune,
      website: website,
    });

    // Set currentUser as the newly created user
    const currentUser = auth.currentUser;

    // Send PIVA to backend
    const apiData = await sendPivaToBackend(piva, user.uid);
    if (apiData && apiData.status === 'success') {
      await setDoc(doc(db, "users", user.uid), apiData.data, { merge: true });
    } else {
      throw new Error("API data processing failed: " + apiData.message);
    }

    // Send message to server
    const userEmail = user.email;
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    if (!API_BASE_URL) {
      throw new Error("API_BASE_URL is not defined in environment variables.");
    }

    const response = await fetch(`${API_BASE_URL}/api/contactus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sender_email: userEmail, incentive_id: 'New user signed up!' }),
    });

    if (!response.ok) {
      let errorMsg = 'Failed to send sign-up notification to the server.';
      try {
        const errorData = await response.json();
        if (errorData && errorData.message) {
          errorMsg = `Server Error: ${errorData.message}`;
        }
      } catch (parseError) {
        console.error('Failed to parse server error response:', parseError);
      }
      throw new Error(errorMsg);
    }

    const serverResponse = await response.json();
    //console.log('Server Response:', serverResponse);

    // Fetch additional user data from Firestore
    const userData = await fetchUserData(currentUser);

    if (userData && selectedRole === "azienda") {
      await sendUserInfo(
        userData.regione,
        userData.companySize,
        userData.companyTurnover,
        userData.comune,
        userData.companyEmployees,
        userData.companyAteco,
        user.uid // or userData.userId if you prefer
      );
    } else {
      console.log("User role is 'commercialista'; skipping sendUserInfo.");
    }

    handleNavigate(selectedRole);
    // Set success modal message
    setIsSuccess(true);
    setModalMessage("Grazie per esserti iscritto!");
    setShowSuccess(true);

  } catch (error) {
    console.error("Sign-up failed:", error);
    setIsSuccess(false);
    setErrorMessage(error.message || 'An unexpected error occurred.');
    setShowModal(false);
  } finally {
    setButtonText('Andiamo!');
    setIsLoading(false);
  }
};


  ///////////// 
  useEffect(() => {
    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  async function fetchUserData(currentUser) {
    if (currentUser && currentUser.email) {
      try {
        const usersCollectionRef = collection(db, "users");
        const q = query(
          usersCollectionRef,
          where("email", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          const userDoc = querySnapshot.docs[0];
  
          // Log the fetched user data
          //console.log("Fetched user data from Firestore:", userData);
  
          // Return userData and user ID
          return { ...userData, userId: userDoc.id };
        } else {
          console.log("No user data found for email:", currentUser.email);
          return null;
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    } else {
      console.log("currentUser is not defined yet");
      return null;
    }
  }
  

 /*  const testFetchUserData = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
  
    if (currentUser) {
      console.log("Triggering fetchUserData with currentUser:", currentUser);
      await fetchUserData(currentUser);
    } else {
      console.log("No currentUser is signed in or available.");
    }
  }; */
  


  const extractBaseCode = (ateco) => {
    // Extract only the numeric part (before any dash or text)
    const numericPart = ateco.split(" - ")[0].trim();

    // Handle cases with one decimal place (e.g., '27.9')
    if (/^\d{2}\.\d$/.test(numericPart)) {
      // Append '0' to make it '27.90'
      return numericPart + "0";
    }

    // Handle cases with two or more decimal places (e.g., '70.2209')
    if (/^\d{2}\.\d{2,}/.test(numericPart)) {
      // Truncate to two decimal places
      return numericPart.slice(0, 5);
    }

    // If no match, return the original numeric part
    return numericPart;
  };

const sendUserInfo = async (
  regione,
  companySize,
  companyTurnover,
  comune,
  companyEmployees,
  companyAteco,
  userId,
  userRole
) => {
  try {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const baseCode = companyAteco ? extractBaseCode(companyAteco) : "";

    const userInfo = {
      regione: regione,
      companySize: companySize,
      companyTurnover: companyTurnover,
      comune: comune,
      companyEmployees: companyEmployees,
      companyAteco: companyAteco,
      codicel3: baseCode,
      user_id: userId,
    };

    //console.log("Sending user info:", userInfo);

    const response = await fetch(`${API_BASE_URL}/api/send_user_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log("User info sent successfully:", data.message);
  } catch (error) {
    console.error("Error sending user info:", error);
  }
};
  
  

const handleNavigate = (role) => {
  setShowModal(false); // Hide modal
  if (role === "azienda") {
    navigate("/strategy/asst_9BGy863ytF0SLw8H0PyyLZrF/"); // Navigate to azienda strategy page
  } else if (role === "commercialista") {
    navigate("/postsignupnote"); // Navigate to postsignupnote for commercialista
  } else {
    console.log("Unexpected role:", role);
  }
};






  

  // const updateUserDb = async () => {
  //   const auth = getAuth();
  //   const user = auth.currentUser;
  
  //   if (user) { // Removed the emailVerified check
  //     console.log("Proceeding with Firestore operation...");
  //     try {
  //       await setDoc(doc(db, "users", user.uid), {
  //         email: user.email,
  //         firstName: firstName,
  //         lastName: lastName,
  //         role: selectedRole,
  //         regione: selectedRegione,
  //         //atecoL3: selectedAtecoL3 ? selectedAtecoL3.value : null,
  //         website: website,
  //         piva: piva,
  //       });
  //       console.log("Firestore document for user created successfully.");

  //       sendPivaToBackend(piva, user.uid);

  //       // Consider updating any success UI or navigation here, if needed
  //     } catch (firestoreError) {
  //       console.error("Firestore operation failed:", firestoreError);
  //       setErrorMessage("Failed to create user profile, please try again.");
  //     }
  //   } else {
  //     console.error("No user is signed in.");
  //     setErrorMessage("Please sign in to proceed.");
  //   }
  // };
  
//   const sendPivaToBackend = async (piva, docId) => {
//     console.log("Sending piva to backend:", piva);
//     const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//     try {
//         const payload = { piva, docId };
//         console.log("Payload being sent:", JSON.stringify(payload));
//         const response = await fetch(`${API_BASE_URL}/api/piva`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(payload)
//         });
//         const responseData = await response.json();
//         console.log("Backend process triggered successfully:", responseData);
//     } catch (apiError) {
//         console.error("API call failed:", apiError);
//     }
// };
  
const updateUserDb = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    console.log("Proceeding with initial Firestore operation...");
    try {
      // Assume you fetch 'selectedRole' and other fields from a form or component state
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        role: selectedRole, // Make sure this is captured or confirmed post-signup
        // other fields...
      }, { merge: true }); // Use merge to update only specified fields

      console.log("User document updated with dynamic role and other data");

      // Further backend interaction as before...
    } catch (error) {
      console.error("Firestore operation failed:", error);
      setErrorMessage("Failed to create/update user profile, please try again.");
    }
  } else {
    console.error("No user is signed in.");
    setErrorMessage("Please sign in to proceed.");
  }
};



const sendPivaToBackend = async (piva, docId) => {
  //console.log("Sending piva to backend:", piva);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  try {
    const payload = { piva, docId };
    const response = await fetch(`${API_BASE_URL}/api/piva`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    });
    if (!response.ok) throw new Error("Network response was not ok");
    const responseData = await response.json();
    //console.log("Backend process triggered successfully:", responseData);
    return responseData; // Ensure this contains the status and data
  } catch (apiError) {
    console.error("API call failed:", apiError);
    return { status: "error", message: apiError.toString() };
  }
};


const handlePivaChange = (e) => {
  const value = e.target.value;

  // Update the piva state
  setPiva(value);

  // Check if the value is 11 digits long
  if (value.length !== 11 || !/^\d+$/.test(value)) {
    setError('Partita IVA must be exactly 11 digits.');
  } else {
    setError('');
  }
};
  

  const containerStyle = {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px", // Added padding to prevent content from touching the edges
  };

  const formStyle = {
    padding: "20px",
    border: "1px solid white",
    borderRadius: "5px",
    width: "100%", // The form takes up 100% of its parent's width
    maxWidth: "400px", // Limits the form's width to 400px
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  // Adjusted inputStyle to fill the available width in form
  const inputStyle = {
    width: "90%", // The input should take the full width of the form
    height: "2.5rem",
    borderRadius: "5px",
    padding: "0 10px",
  };

  // Message styles seem to be for a different element, ensure they're applied correctly
  const messageStyle = {
    background: "white",
    color: "black",
    minHeight: "10vh",
    backgroundColor: "rgb(225, 141, 23)",
    fontSize: "20px",
    lineHeight: "40px",
    borderRadius: "5px",
    textAlign: "center",
    fontWeight: "bold",
    border: "2px solid white",
  };

  // This might be for wrapping the message, ensure it's used where intended
  const messageContainerStyle = {
    fontFamily: "sans-serif",
    fontSize: "20px",
    background: "black",
    color: "black",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    zIndex: 9999,
    textAlign: "left",
  };

  /*role*/
  const handleRoleChange = (e) => {
    const selectedOption = e.target.value;
  
    if (selectedOption === "commercialista") {
      setSelectedRole("commercialista");
      setService("2Jewsb3lxiWlTMb7dQHw"); // Service ID for commercialista
    } else if (selectedOption === "azienda") {
      setSelectedRole("azienda");
      setService("Oi0KXBdsdKc4NZkGXZGa"); // Service ID for azienda
    }
  };

  const [service, setService] = useState("");

  /*regione*/
  const handleRegionChange = (e) => {
    const selectedOption = e.target.value;

    if (selectedOption === "Lombardia") {
      setSelectedRegione("Lombardia");
    } else {
      setSelectedRegione("Toscana");
      setSelectedRegione(selectedOption);
    }
  };

  const submitUserInfo = async () => {
    const querySnapshot = await getDocs(
      query(collection(db, "users"), where("email", "==", email))
    );

    if (!querySnapshot.empty) {
      // If the querySnapshot is not empty, a document with the email already exists
      setErrorMessage(
        "This email is already in use. Please login or reset your password."
      );
      setSuccessMessage(""); // Clear any previous success message
      return; // Exit the function to prevent creating a new document
    }

    try {
      await addDoc(collection(db, "users"), {
        email: email, // Assuming 'user' is defined in your component's state/context
        firstName: firstName, // Assuming this is defined in your state/context
        lastName: lastName, // Assuming this is defined in your state/context
        role: selectedRole, // The selected role from your form
        regione: selectedRegione, // The selected region from your form
        comune: selectedComune,
        //atecoL3: selectedAtecoL3 ? selectedAtecoL3.value : null,
        website: website,
        piva: piva 
      });
      console.log("Document successfully written!");
      setSuccessMessage("Sign up successful! Welcome to our platform.");
      setErrorMessage("");
      // Handle successful submission here (e.g., showing a success message, clearing the form, etc.)
    } catch (err) {
      console.error("Error adding document: ", err);
      setErrorMessage(
        "An error occurred. If you have already signed up, you may need to reset your password."
      );
      setSuccessMessage("");
      // Handle submission error here (e.g., showing an error message)
    }
  };

  const newEventRoleOptions = ["Commercialista/Professionista", "Azienda"];
  const newEventRegioneOptions = [
    "Abruzzo",
    "Aosta",
    "Basilicata",
    "Calabria",
    "Campania",
    "Emilia Romagna",
    "Friuli Venezia Giulia",
    "Lazio",
    "Liguria",
    "Lombardia",
    "Marche",
    "Molise",
    "Piemonte",
    "Puglia",
    "Sardinia",
    "Sicilia",
    "Trentino/Alto Adige/Sudtirol",
    "Toscana",
    "Umbria",
    "Veneto",
  ];

  /* get ateco codes from server*/
  useEffect(() => {
    //console.log('API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);
    //fetch("http://localhost:8080/getAtecoCodes")
    // Assuming `fetchAtecoCodes` is your function to fetch the list
    //const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/getAtecoCodes`;
    //console.log('Requesting ATECO codes from:', endpoint);
    fetch(endpoint)
    .then(response => {
      //console.log(`Response from ${endpoint}:`, response.status, response.statusText);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      //console.log('Received data:', data);
      // Transform the data into the format expected by react-select
      const transformedOptions = data.map((code) => ({
        value: code,
        label: code,
      }));
      setOptions(transformedOptions);
    })
    .catch(error => {
      console.error("Error fetching ATECO codes:", error);
    });
}, []);

/* useEffect(() => {
  if (selectedOption) {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const endpoint = `${API_BASE_URL}/api/getAtecoLevel3?level1Code=${encodeURIComponent(selectedOption.value)}`;
      
      console.log('Requesting Level 3 ATECO codes from:', endpoint);
      fetch(endpoint)
          .then((response) => {
              console.log(`Response from ${endpoint}:`, response.status, response.statusText);
              if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();
          })
          .then((data) => {
              console.log('Received Level 3 data:', data);
              if (!Array.isArray(data) || data.length === 0) {
                  console.error("Data is not an array or is empty");
                  return;
              }
              const formattedOptions = data.map((item) => ({
                  value: item,
                  label: item,
              }));
              setOptionsL3(formattedOptions);
          })
          .catch((error) => {
              console.error("Error fetching level 3 ATECO codes:", error);
          });
  }
}, [selectedOption]);
 */
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    //console.log(`Option selected:`, selectedOption);
  };

  //const [selectedAtecoL3, setSelectedAtecoL3] = useState(null); // Ensure this matches the expected structure
  // const [optionsL3, setOptionsL3] = useState([]);

  // const handleChangeL3 = (selectedOption) => {
  //   //console.log("Selected L3 option:", selectedOption);
  //   setSelectedAtecoL3(selectedOption);
  // };

  const customStyles = {
    // Other styles...
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black", // Change text color based on selection
      backgroundColor: state.isFocused ? "blue" : "white", // Change background on focus
      // Additional styling for options can go here
    }),
    // Adjustments for valueContainer and singleValue as previously discussed
    valueContainer: (provided, state) => ({
      ...provided,
      height: "auto",
      overflow: "visible",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      overflow: "visible",
      position: "static",
      top: "auto",
      transform: "none",
      maxWidth: "none",
      whiteSpace: "normal",
      lineHeight: "normal",
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
  };

  //console.log("Level 1 Selected:", !!selectedAtecoL1);
  //console.log("optionsL3:", optionsL3);

  const unifiedInputStyle = {
    width: "100%", // Adjusted width as per your example
    height: "40px", // Increased height
    marginBottom: "20px",
    fontSize: "18px",
    // Add other styles as needed for consistent appearance
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      ...unifiedInputStyle,
      height: "'auto'", // Overriding height for react-select control to auto
      minHeight: "40px", // Ensuring the control has a minimum height
      fontSize: "18px",
    }),
    // Add other component customizations as needed
  };

  const closePopup = () => {
    setSuccessMessage(""); // Clear success message
    setErrorMessage(""); // Clear error message
  };

  const [passwordError, setPasswordError] = useState('');
  const [firebaseError, setFirebaseError] = useState('');

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    
    // Reset Firebase error when user modifies input
    setFirebaseError('');
    
    // Client-side password validation
    if (newPassword.length < 6) {
      setPasswordError('La password deve essere lunga almeno 6 caratteri');
    } else {
      setPasswordError('');
    }
  };


  ////*backend processing of FilteredData & HF*////////////////////////////

  

  ////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div style={containerStyle}>
      <div style={formStyle}>
        <h2 style={{ color: "white" }}>SignUp</h2>
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "left" }}
        >
          {" "}
          Azienda / Singolo Partita Iva o Multi Partita Iva come Commercialista o Professionista? *{" "}
        </div>

        <select
          value={selectedRole}
          onChange={handleRoleChange}
          style={unifiedInputStyle}
          required
        >
          <option value="defaultRole">Scegli un ruolo</option>
          {newEventRoleOptions.map((role, index) => (
            <option
              key={index}
              value={
                role === "Commercialista/Professionista"
                  ? "commercialista" // Ensuring 'Commercialista' is set for this option
                  : role === "Azienda"
                  ? "azienda" // Using lowercase 'azienda' for this option
                  : role // For any other roles, use the role as is
              }
            >
              {role}
            </option>
          ))}
        </select>


        <div className="side-by-side" styles={customSelectStyles}>
          {" "}
          Regione *{" "}
        </div>

        <select
          value={selectedRegione}
          onChange={handleRegionChange}
          style={unifiedInputStyle}
          required
        >
          <option value="">Scegli un regione</option>
          {newEventRegioneOptions.map((regione, index) => (
            <option key={index}>{regione}</option>
          ))}
        </select>
        
        <div>
            <label>Comune *</label>
            <input
              className="boxes-signup"
              type="text"
              placeholder="eg: Monza"
              value={selectedComune}
              onChange={(e) => setSelectedComune(e.target.value)}
              style={unifiedInputStyle}
              required
            />
          </div>

        <div>
            <label>Website </label>
            <input
              className="boxes-signup"
              type="text"
              placeholder="lasciare vuoto se n/a"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              style={unifiedInputStyle}
            />
          </div>
          <div>
      <label>Partita Iva *</label>
      <input
        className="boxes-signup"
        type="text"
        placeholder="es: 12345678901"
        value={piva}
        onChange={handlePivaChange}
        required
        style={unifiedInputStyle}
      />
      {error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
       {/* <div>
          <label>Settore:</label>
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            isMulti={false} // Ensure this is set to false or omitted, as we're not using multi-select
            className="basic-single"
            classNamePrefix="select"
            isClearable
            styles={customStyles}
          />
          <div style={{ margin: "20px" }}></div>
          <label>Sub-settore:</label>
          <Select
            value={selectedAtecoL3}
            onChange={handleChangeL3}
            options={optionsL3}
            isClearable
            className="basic-single"
            classNamePrefix="select"
            styles={customStyles}
            placeholder="Select Level 3 ATECO"
            isDisabled={optionsL3.length === 0} // Enable when options are available
          />
        </div>*/}

        <h2 style={{ color: "white" }}>Detagli di Login</h2>
        <form>
          <div>
            <label>Nome *</label>
            <input
              className="boxes-signup"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              style={unifiedInputStyle}
            />
          </div>
          <div>
            <label>Cognome *</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              style={unifiedInputStyle}
            />
          </div>
          <div>
            <label>Email *</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={unifiedInputStyle}
            />
          </div>
          <div>
            <label>Password *</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
              style={unifiedInputStyle}
            />
            {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
          </div>

          <div style={{ marginBottom: '15px' }}>
      <input
        type="checkbox"
        id="privacyPolicy"
        checked={acceptPrivacyPolicy}
        onChange={handlePrivacyPolicyChange}
      />
      <label htmlFor="privacyPolicy" style={{ marginLeft: '5px' }}>
        I accept the{' '}
        <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </Link>
        *
      </label>
      {privacyPolicyError && <p style={{ color: 'red' }}>{privacyPolicyError}</p>}
    </div>

          {firebaseError && <p style={{ color: 'red' }}>{firebaseError}</p>}
        </form>
        <div>
        <button
        type="button"
        onClick={(e) => handleSignUp(e)}
        style={{
          background: "none",
          color: "white",
          border: "1px solid rgb(225, 141, 23)",
          borderRadius: "5px",
          padding: "10px 20px",
          cursor: "pointer",
          textAlign: "center",
        }}
        disabled={isLoading} // Disable the button while loading
      >
        {buttonText}
      </button>

      {/* Modal Popup */}
      {showModal && (
  <div className="modal-overlay-sign">
    <div className="modal-content-sign">
      <h2 className="modal-title-sign">{modalMessage}</h2> {/* Ensure text is visible */}
      {showSuccess && (
        <button
          onClick={handleNavigate}
          className="modal-button-sign"
        >
          Cominciamo
        </button>
      )}
    </div>
  </div>
)}

      {/*     {isLoading && (
        <div className="loading-spinner"></div>
      )}

          {(successMessage || errorMessage) && (
        <div className="popup-su">
          <div className="popup-content-su">
            <button className="close-button-su" onClick={closePopup}>X</button>
            {successMessage && (
              <div style={{ color: "green" }}>{successMessage}</div>
            )}
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </div>
        </div>
      )} */}
           
          {/* {successMessage && (
            <div>
              <div style={{ color: "green" }}>{successMessage}</div>
              <button
                onClick={() => {
                  updateUserDb(piva);
                  navigate("/");
                }}
                style={{
                  marginTop: "10px",
                  padding: "10px 20px",
                  background: "blue",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Go to Home Page
              </button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default SignUp;
