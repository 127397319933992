///imports
import React, {
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "./config/firebase.js";
import {
  getDocs,
  collection,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
} from "firebase/firestore";
import Navbar from "./components/Navbar/index.js";
import Home from "./pages/home.js";
import SignUp from "./pages/signup.js";
import Contact from "./pages/contact.js";
import DisclaimerCampus from "./pages/disclaimer-campus.js";
import LogIn from "./pages/login.js";
//import Campus from "./pages/campus.js";
import Checkout from "./pages/checkout.js";
import Cart from "./pages/cart.js";
//import PaymentSuccess from "./pages/paymentSuccess.js";
import Users from "./pages/users.js";
import Layout from "./Layout.js";
import IncentiveSearch from "./pages/agent2.js";
import IncentiveSearchCommercialista from "./pages/agent2Commercialista.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate
} from "react-router-dom";
import "./App.css";
import DisclaimerModal from "./DisclaimerModal.js";
import { CartProvider } from "./pages/cartContext.js";
import UserPromptModal from "..//src/components/userPrompt.js";
import ThreadUnic from "./pages/threadUnic.js";
import Preapply from "./pages/preApply.js";
//import IncentiveUnic from "./pages/incentiveUnic.js";
//import Survey from "./pages/survey.js";
//import WebBot from "./pages/webBot.js";
import HomeBot from "./pages/homechatbot.js";
//import LandingWebBot from "./pages/landingchatbot.js";
import OldNews from "./pages/oldnews.js";
import ServiceOffers from "./pages/serviceOffers.js";
import ServiceOffersProfs from "./pages/serviceOffersProfs.js";
import AboutUser from "./pages/aboutUser.js";
import IncentiveGuessGame from "./pages/incentiveGuessGame.js";
import AboutUserPro from "./pages/aboutpros.js";
import SuccessNotification from "./pages/successNotification.js";
import OldIncentives from "./pages/oldincentives.js";
import { useParams } from "react-router-dom";
//import landingPic from './landing-pic2.png';
//import landingPic from "../src/noun-pyramid-6037917.png";
import credsPic from "../src/noun-apply-job-6223684.png";
import qualifyPic from "../src/noun-award-6182518.png";
import searchPic from "../src/noun-search-6811990.png";
import applyPic from "../src/noun-apply-1903010.png";
import digithon from "../src/digithon.png";
import {
  AuthProvider,
  useAuth,
  PrivateRoute,
  UserRoute,
} from "./components/auth-email.js";
import StageIndicator from "..//src/components/stages.js";
import UsePageTracking from "..//src/usepageTracking.js";
import PostSignup from "./pages/postSignup.js";
import PostSignupNote from "./pages/postsignupnote.js";
import Goals from "./pages/goalsPage.js";
import UserProfile from "./pages/userProfile.js";
//import PostSignupComms from "./pages/postSignupComms.js";
import Typewriter from "..//src/components/Typewriter.js";
import InputWithFlashingPlaceholder from "..//src/components/placeholder.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Incentivato from "..//src/landing-pic.png"
import PlaceholderReturn from "./placeholderreturn.js";
import NotFound from "./components/notfound.js";
import DiventaPartner from "../src/pages/partner.js";

function App() {
  const headerStyles = {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "1px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    wordSpacing: "6px",
    fontSize: "35px",
    //animation: "flash 3s infinite",
  };

  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  useEffect(() => {
    const isDisclaimerAccepted =
      localStorage.getItem("disclaimerAccepted") === "true";
    setDisclaimerAccepted(isDisclaimerAccepted);
  }, []);

  const handleAcceptDisclaimer = () => {
    localStorage.setItem("disclaimerAccepted", "true");
    setDisclaimerAccepted(true);
  };

/*   const SearchRoute = () => {
    const { userRole } = useAuth();

    return (
      <>
        <StageIndicator currentStage={1} />
        {userRole === "commercialista" ? (
          <IncentiveSearchCommercialista />
        ) : (
          <IncentiveSearch />
        )}
      </>
    );
  }; */

  return (
    <CartProvider>
      <div>
        <Router>
          <AuthProvider>
            <UsePageTracking />
             {!disclaimerAccepted && (
              <DisclaimerModal onAccept={handleAcceptDisclaimer} />
            )} 
            <Navbar />{" "}
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <RouteContentWithImage>
                    <Layout />
                  </RouteContentWithImage>
                }
              />
              <Route path="/home" element={<Home />} />
              <Route path="/create" element={<PrivateRoute></PrivateRoute>} />
              <Route path="/service-offers" element={<ServiceOffers />} />
              <Route path="/service-offers-pros" element={<ServiceOffersProfs />} />
              <Route path="/aboutUser" element={<AboutUser />} />
              <Route path="/aboutUserPro" element={<AboutUserPro />} />
              <Route path="/userprofile" element={<UserProfile />} />
              <Route path="/privacypolicy" element={<Contact />} />
              <Route path="/disclaimer-campus" element={<DisclaimerCampus />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="/oldIncentives" element={<OldIncentives />} />
              <Route path="/news" element={<OldNews />} />
              {/* <Route
                path="/search"
                element={
                  <>
                    <StageIndicator currentStage={1} />
                    <IncentiveSearch />
                  </>
                }
              />  */}
              <Route path="/search" element={<IncentiveSearch />} />
              <Route path="/searchpros" element={<IncentiveSearchCommercialista />} />
              <Route path="/diventapartner" element={<DiventaPartner />} />
              <Route path="/threadUnic/:threadId" element={<ThreadUnic />} />
              <Route
                path="/threadUnic/:assistant/:threadId/:incentiveId"
                element={
                  <>
                    <StageIndicator currentStage={2} />
                    <ThreadUnic />
                  </>
                }
              />
              <Route
                path="/threadUnic/:assistant/:threadId/:incentiveId/Apply"
                element={
                  <>
                    <StageIndicator currentStage={3} />
                    <Preapply />
                  </>
                }
              />
              
            {/*  <Route
                path="/:assistant/:incentiveId"
                element={
                  <>
                    {console.log("Rendering IncentiveUnic component with route parameters")}
                    <IncentiveUnic />
                  </>
                }
              /> */}
              <Route
                path="/:assistant/:incentiveId"
                element={() => {
                  window.location.href = `https://incentivato.it${window.location.pathname}`;
                  return null;
                }}
              />
              <Route path="*" element={<PlaceholderReturn />} />
              <Route path="/placeholder-test" element={<PlaceholderReturn />} />
              <Route path="*" element={<NotFound />} /> 
              {/* <Route
                path="/survey/:assistant/:threadId/"
                element={
                  <>
                    <Survey />
                  </>
                }
              /> */}
              {/* <Route
                path="/webBot/:assistant/:threadId/"
                element={
                  <>
                    <WebBot />
                  </>
                }
              />
              <Route
                path="/webBot/:assistant/"
                element={<WebBot />}
              />
              <Route
                path="/landingbot/:assistant/:threadId/"
                element={
                  <>
                    <LandingWebBot />
                  </>
                }
              />
              <Route
                path="/landingbot/:assistant/"
                element={<LandingWebBot />}
              />*/}
              <Route
                path="/strategy/:assistant/:threadId/"
                element={
                  <>
                    <PostSignup />
                  </>
                }
              />
              <Route path="/strategy/:assistant/" element={<PostSignup />} />
              <Route path="/postsignupnote" element={<PostSignupNote />} />
              <Route path="/goals/:assistant/" element={<Goals />} />
              <Route
                path="/goals/:assistant/:threadId/"
                element={
                  <>
                    <Goals />
                  </>
                }
              />
              <Route path="/strategy/:assistant/" element={<PostSignup />} />
             
              {/* <Route
                path="/campusFeedback"
                element={
                  <UserRoute>
                    {" "}
                    <Initmaps />{" "}
                  </UserRoute>
                }
              /> */}
              <Route path="/cart" element={<Cart />} />
              <Route path="/paymentSuccess" element={<SuccessNotification />} />
              <Route path="/IGG" element={<IncentiveGuessGame />} />
               <Route path="/checkout" element={<Checkout />} />
              <Route path="/userlogin" element={<Users />} />
             {/*  <Route
                path="/paymentSuccess/:docId/:paymentIntentId"
                element={<PaymentSuccess />}
              /> */}
              {/* <Route path="/return" element={<Return />} /> */}
              {/* <Route path="/ordercomplete" element={<Return />} /> */}
            </Routes>
          </AuthProvider>
        </Router>
        {/* <div className="email">Email: info@capital-ai.eu </div> */}
        <div className="sources">Sources: OpenData (incentivi.gov.it) </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "black", // Added background to the container instead of the image itself
          }}
        >
          <img src={digithon} alt="A" className="digithon" />
        </div>
      </div>
    </CartProvider>
  );
}

// Custom component for conditionally rendering content with an image
function RouteContentWithImage() {
  // Get the current route pathname
  const pathname = window.location.pathname;

  // Conditionally render the image
  const renderImage = pathname === "/";

  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////
  //text entry to fb db
  const [newQ1, setNewQ1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const questionsCollectionRef = collection(db, "questions");
  const threadsCollectionRef = collection(db, "threads");
  const newsCollectionRef = collection(db, "news");
  const [lastVisible, setLastVisible] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      getEnrichedThreads();
    } else {
      //console.log("Waiting for user authentication...");
    }
  }, [auth.currentUser]); // Or simply []

  const getEnrichedThreads = async () => {
    try {
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      if (!userId) {
        //console.error("User is not authenticated.");
        return;
      }

      // Fetch all threads and map them by threadId
      const threadTitles = {};
      const threadsSnapshot = await getDocs(threadsCollectionRef);
      threadsSnapshot.forEach((doc) => {
        const threadData = doc.data();
        threadTitles[threadData.threadId] = threadData.title;
      });

      // Fetch questions and enrich them with titles
      const q = query(
        questionsCollectionRef,
        where("userId", "==", userId),
        orderBy("timestamp", "desc")
      );
      const querySnapshot = await getDocs(q);

      let threadsArray = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        title: threadTitles[doc.data().threadId] || "No Title",
      }));

      // Deduplicate threads based on threadId, maintaining the latest entry (assuming latest by timestamp)
      const uniqueThreads = {};
      threadsArray.forEach((thread) => {
        // assuming threadId is always available
        if (
          !uniqueThreads[thread.threadId] ||
          uniqueThreads[thread.threadId].timestamp.seconds <
            thread.timestamp.seconds
        ) {
          uniqueThreads[thread.threadId] = thread;
        }
      });

      // Convert the uniqueThreads object back to an array
      setThreads(Object.values(uniqueThreads));
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    getEnrichedThreads();
  }, []); // Dependency array is empty, so this runs once on component mount

  const [showUserPrompt, setShowUserPrompt] = useState(false);
  useEffect(() => {
    const userHasVisited = localStorage.getItem("userHasVisited");
    if (!userHasVisited) {
      setShowUserPrompt(true);
    }
  }, []);
  const handleClose = () => {
    localStorage.setItem("userHasVisited", "true");
    setShowUserPrompt(false);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////

  const [question, setQuestion] = useState("");
  const [docId, setDocId] = useState("");
  const [threadId, setThreadId] = useState("");
  //const [jsonData, setJsonData] = useState(Opendata); // Ensure Opendata is defined
  const [apiResponse, setApiResponse] = useState("");
  const [messages, setMessages] = useState([
    {
      role: "system",
      content:
        "You are a helpful assistant with good general knowledge for checking facts",
      file_ids: "123",
    },
  ]);

  const [currentThreadId, setCurrentThreadId] = useState(null);

  const filteredQuestions = questionList.filter((question) =>
    currentThreadId ? question.threadId === currentThreadId : true
  );

  const [threads, setThreads] = useState([]);

  // useEffect(() => {
  //   const fetchThreads = async () => {
  //     const threadsSnapshot = await getDocs(threadsCollectionRef);

  //     let threadsArray = threadsSnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       threadId: doc.data().threadId,
  //       title: doc.data().title,
  //       timestamp: doc.data().timestamp,
  //     }));

  //     // Sort threadsArray by timestamp in descending order
  //     threadsArray.sort((a, b) => {
  //       const timestampA = a.timestamp ? a.timestamp.seconds : 0;
  //       const timestampB = b.timestamp ? b.timestamp.seconds : 0;
  //       return timestampB - timestampA;
  //     });

  //     // Filter for unique threadIds
  //     const uniqueThreads = threadsArray.reduce((acc, current) => {
  //       const x = acc.find((item) => item.threadId === current.threadId);
  //       if (!x) {
  //         return acc.concat([current]);
  //       } else {
  //         return acc;
  //       }
  //     }, []);

  //     setThreads(uniqueThreads);
  //   };

  //   fetchThreads();
  // }, []);

  const [title, setTitle] = useState("");
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    console.log("Title updated:", event.target.value);
  };

  const handleThreadUnic = (threadId) => {
    navigate(`/threadUnic/${threadId}`);
  };

  const startNewConversation = async (
    assistant = "asst_EcSBNs1B1atKqgqVaHwvFtAb"
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, mi piace fare questo indaggio`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userMessage }),
      });
      //console.log("API Base URL:", API_BASE_URL);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("Response data:", data); // Log the entire response data
      localStorage.setItem("currentThreadId", data.thread_id);
      //console.log("Stored thread_id in localStorage:", data.thread_id);

      if (data.thread_id && assistant) {
        //console.log("New thread ID:", data.thread_id, "assistant:", assistant);

      
        /* navigate(`/survey/${assistant}/${data.thread_id}`, {
          state: { title: title },
        }) */;
      } else {
        console.error(
          "Thread ID or Assistant ID was not returned from the server."
        );
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      const newsCollectionRef = collection(db, "news");
      const q = query(
        newsCollectionRef,
        orderBy("timestamp", "desc"),
        limit(3)
      );
      const querySnapshot = await getDocs(q);
      const fetchedNews = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNews(fetchedNews);
    };

    fetchNews();
  }, []);

  const handleLike = async (articleId, currentLikes) => {
    const articleRef = doc(db, "news", articleId);
    const newLikes =
      typeof currentLikes === "number" && !isNaN(currentLikes)
        ? currentLikes + 1
        : 1;
    await updateDoc(articleRef, {
      likes: newLikes,
    });

    // Update the state immediately for UI feedback
    setNews((prevNews) =>
      prevNews.map((article) => {
        if (article.id === articleId) {
          return { ...article, likes: newLikes };
        }
        return article;
      })
    );
  };

  const [targetRef, setTargetRef] = useState(null);

  const NewsSlideshow = ({ news }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      // Ensure there is news data before setting the interval
      if (news && news.length > 0) {
        const intervalId = setInterval(() => {
          setCurrentIndex((current) => (current + 1) % news.length);
        }, 8000); // Rotate news every 5 seconds

        return () => clearInterval(intervalId); // Clean up the interval on unmount
      }
    }, [news]); // Depend on 'news' to restart the effect when news updates

    if (!news || news.length === 0) {
      return <p>Loading news...</p>; // Handle case where news is not yet available
    }

    return (
      <div className="news-slideshow">
        {news.length > 0 ? (
          <div className="news-article">
            <h2 className="article-title">{news[currentIndex].title}</h2>
            <img
              src={news[currentIndex].image_url}
              className="image-article"
              alt={news[currentIndex].title}
            />
            <p className="article-summary">{news[currentIndex].summary}</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingLeft: "5px", fontSize: "16px" }}>
                {news[currentIndex].likes}
              </span>
            </div>
          </div>
        ) : (
          <p>Loading news...</p>
        )}
      </div>
    );
  };

  const videoRef = useRef(null); // Create a ref to access the video element
  const [isPlaying, setIsPlaying] = React.useState(false); // State to track if the video is playing

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  return (
    <div style={{ position: "relative", textAlign: "center", border: "none" }}>
      {/* <Slideshow className="general-css text-loop" /> */}
      <div className="container-flex">
        <div className="landing-width">
          <div className="newconvo-title">
            <div className="sloganContainer">
              <div className="frontpage-container">
                <div className="sloganStyles2" style={{ order: 2 }}>
                  ACCELERATED FUNDING APPLICATIONS
                </div>
                <div className="sloganStyles" style={{ order: 2 }}>
                  Streamline your<span className="letterSpacing"></span>Finance
                </div>
                <div
                  className="sloganStyles"
                  style={{ order: 1, color: "#FF5758" }}
                >
                  with&nbsp;
                  <span style={{ color: "#FF5758" }}>
                    <Slideshow />
                  </span>
                  <span className="letterSpacing"></span>
                </div>
                <div className="captionStyles" style={{ order: 4 }}>
                  Incentivato aiuta ad accelerare il
                  ricerca e di richiesta di finanziamenti.
                </div>
                <div className="captionStyles" style={{ order: 4 }}>
                  Ci pensiamo noi mentre tu ti concentri sulla tua attività!
                </div>
                <div className="box-container-landing"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="side-contentv">
          {/*  <NewsSlideshow news={news} className="general-css text-loop" /> */}
          <div className="video-container">
            <video
              ref={videoRef}
              className="video-responsive"
              muted
              playsInline
              webkit-playsinline="true"
              poster={Incentivato}
              style={{ width: "100%", height: "auto" }} // Responsive video styling
            >
              <source src="Incentivato2112.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {!isPlaying && ( // Only show the play button if the video is not playing
              <button className="play-button" onClick={togglePlayPause}>
                <div className="play-icon"></div>{" "}
                {/* Triangle inside the button */}
              </button>
            )}
          </div>
        </div>
      </div>
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
      }}>
        <span className="dice">🎲</span>
        <IncentiveGuessGame />
      </div>

          {/* <div className="sloganStylesH">
            Fai una domanda
            </div>
            <div className="sloganStyles3">
            <div
              style={{
                textAlign: "left",
                fontFamily: "sans-serif",
                fontSize: "24px",
                color: "#092f7a",
              }}
            >
              <Typewriter text={ 'Cosa stai cercando?'} speed={55} />
            </div>
            <HomeBot />
          </div> */}
      {/* <div class="line-container">
  <div className="chat-area"></div>
  </div> */}
      <div>
      <div className="process-wrapper">
      <div>
        <h1 className="process-title">
        Come aiutiamo le imprese?
        </h1>
      </div>
      <div className="process-container">
        <div className="process-box" style={{ cursor: "pointer" }}>
          <div className="process-number">1</div>
          <p className="process-description-title">
          Democratizzazione dell'accesso
          </p>
          <p className="process-description">
          Rendiamo accessibili gli incentivi finanziari a tutte le PMI,
          eliminando complessità e barriere.
          </p>
        </div>
        <div className="process-box">
          <div className="process-number">2</div>
          <p className="process-description-title">Trasparenza nella Domanda</p>
          <p className="process-description">
          Garantiamo un processo di candidatura completamente trasparente,
              consentendo alle imprese di seguire il percorso dei propri
              incentivi.
          </p>
        </div>
        <div className="process-box" style={{ cursor: "pointer" }}>
          <div className="process-number">3</div>
          <p className="process-description-title">Nessuna Perdita di Tempo</p>
          <p className="process-description">
          Candidati solo per gli incentivi per cui sei idoneo, massimizzando
          così l'efficienza e l'efficacia.
          </p>
        </div>
        <div className="process-box" style={{ cursor: "pointer" }}>
          <div className="process-number">4</div>
          <p className="process-description-title">
          Costi Vantaggiosi
          </p>
          <p className="process-description">
          Forniamo un servizio di qualità superiore a un costo inferiore
          rispetto ai metodi di consulenza tradizionali.
          </p>
        </div>
        <div className="process-box" style={{ cursor: "pointer" }}>
          <div className="process-number">5</div>
          <p className="process-description-title">
            Velocissima
          </p>
          <p className="process-description">
          Utilizzare uno a due clic anziché compilare lunghi moduli manualmente consente di risparmiare tempo, aumentare l'efficienza e semplificare il processo.          </p>
        </div>
        <div className="process-box" style={{ cursor: "pointer" }}>
          <div className="process-number">6</div>
          <p className="process-description-title">
            Gestirlo
          </p>
          <p className="process-description">
          Gestendo i loro documenti e dettagli, possiamo riutilizzarli in futuro, evitando di richiedere nuovamente le stesse informazioni.          </p>
        </div>
      </div>
    </div>

        <div className="solutions">INCENTIVI STRUMENTALI </div>
        <div className="image-fp-container">
          <div className="image-item" style={{ cursor: "pointer" }}>
            <img src={searchPic} alt=" " />
            <p className="type-fonts"> SEARCH</p>
          </div>
          <div className="image-item">
            <img src={qualifyPic} alt=" " />
            <p className="type-fonts">QUALIFY</p>
          </div>
          <div className="image-item" style={{ cursor: "pointer" }}>
            <img src={credsPic} alt=" " />
            <p className="type-fonts"> IDENTIFY</p>
          </div>
          <div className="image-item" style={{ cursor: "pointer" }}>
            <img src={applyPic} alt=" " />
            <p className="type-fonts"> APPLY</p>
          </div>
        </div>
        <div style={{ background: "black" }}>
          <div className="newsunic-container">
            <div className="notizia">NOTIZIA </div>

            <div className="news-container">
              {news.length > 0 ? (
                news.map((article, index) => (
                  <div key={index} className="news-article">
                    <h2 className="article-title">{article.title}</h2>
                    <a
                      href={`https://incentivato.it/${article.assistant}/${article.incentivo_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={article.image_url}
                        className="image-article"
                        alt="News"
                        style={{ cursor: "pointer" }} // Shows pointer to indicate it's clickable
                      />
                    </a>
                    <p className="article-summary">{article.summary}</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button
                        onClick={() => handleLike(article.id, article.likes)}
                        style={{
                          cursor: "pointer",
                          fontSize: "20px",
                          marginRight: "20px",
                          border: "none",
                          padding: "0", // Remove padding inside the button
                          background: "none", // Remove button background color
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                            fill="black"
                          />
                          <path
                            d="M8 20h9.192a2 2 0 0 0 1.832-1.198l2.808-6.419a2 2 0 0 0 .168-.801V11a2 2 0 0 0-2-2h-6.5l1.207-4.424A1.36 1.36 0 0 0 14.003 3v0a1.36 1.36 0 0 0-1.687.388L8.415 8.46A2 2 0 0 0 8 9.68V20Zm0 0H2V10h6v10Z"
                            stroke="#FFF"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <span
                        style={{
                          paddingLeft: "5px",
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "white",
                          background: "black",
                        }}
                      >
                        {article.likes}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading news...</p>
              )}
            </div>
            <div className="margin-landing">
              <div
                style={{
                  background: "#092f7a",
                  display: "flex",
                  justifyContent: "space-evenly",
                  background: "white",
                  border: "none",
                }}
              >
                <button
                  type="button"
                  onClick={() => navigate("/login")}
                  className="footer-button"
                >
                  User Portal{" "}
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/signup")}
                  className="footer-button"
                >
                  SignUp{" "}
                </button>
                {/* <button
                  type="button"
                  onClick={() => navigate("/contact")}
                  className="login-button"
                >
                  Q&A{" "}
                </button> */}
                <button
                  type="button"
                  onClick={() => navigate("/privacypolicy")}
                  className="footer-button"
                >
                  Privacy Policy{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Slideshow = () => {
  const texts = [
    "AI Matching",
    "1Click Applications",
    "Bespoke Alerts",
    "Expert Support",
    "Flexible Pricing",
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current text index and wrap around if necessary
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 1500); // 10000 milliseconds (10 seconds)

    return () => {
      clearInterval(interval); 
    };
  }, []);

  return (
    <div>
      {/* Display the current text */}
      <div className="text-loop">{texts[currentTextIndex]}</div>
    </div>
  );
};

export default App;
