import React from "react";
import { useNavigate } from "react-router-dom";
import psuimg from "../../src/postsignupimg2.png";
import "./allCSS.css";

export default function SuccessNotification() {
  const navigate = useNavigate();

  const handleProceed = () => {
    navigate("/search"); // Adjust the path to your search page route
  };

  return (
    <div className="success-notification-container">
      <div className="success-notification-content">
        <h1 className="success-header">🎉 Congratulazioni per l'Upgrade!</h1>
        <img src={psuimg} alt="Success" className="success-image" />
        <p className="success-message">
          Siamo entusiasti di averti con noi! Ora hai accesso a tutti gli
          strumenti avanzati che Incentivato ti offre per trovare e ottenere
          incentivi. Sei pronto a sfruttare tutte le nuove opportunità?
          <br />
          <br />
          Inizia subito a esplorare e raggiungi i tuoi obiettivi con noi!
        </p>
        <button onClick={handleProceed} className="proceed-button-successpay">
          Procedi alla Ricerca
        </button>
      </div>
    </div>
  );
}
