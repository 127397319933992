import React, { useEffect, useState } from "react";
import { useCart } from "./cartContext.js";
import { Form, Field } from "react-final-form";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import "./checkout.css";
import { db, auth } from "..//..//src/config/firebase.js";
import { collection, getDocs, doc, getDoc, setDoc, arrayUnion} from "firebase/firestore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//import pluto from "..//..//src/pluto3.png";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  useStripe,
  useElements,
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
  Elements,
  CardElement,
  PaymentMethodMessagingElement,
} from "@stripe/react-stripe-js";
import Styles from "..//..//src/pages/Styles.js";
import Card from "./Card.js";
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || "/api";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

//console.log("Stripe Publishable Key:", process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

//console.log("Stripe Promise:", stripePromise);

export default function Checkout() {
  const { cartItems } = useCart();
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [cardholderName, setCardholderName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const location = useLocation(); 
  const { docId, paymentIntentId } = location.state || {};

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////server stuff
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  fetch(`${API_BASE_URL}/api/check-order-status?paymentIntentId=${paymentIntentId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      const { status } = data;
      console.log("Order status:", status);
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  /////END OF SERVER STUFF
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // Initialize Stripe
    const initializeStripe = async () => {
      const stripe = await stripePromise;
      // You can use stripe object here if needed
    };
    initializeStripe();

  });

  useEffect(() => {
    const totalAmount = cartItems.reduce(
      (acc, item) => acc + (item.quantity || 1) * parseFloat(item.selectedPrice || 0), // Use selectedPrice here
      0
    );
    setTotal(totalAmount);
  }, [cartItems]);

  const handlePayment = () => {
    // Implement the logic to handle payment
    // After successful payment, you might want to navigate to a success page or clear the cart
    console.log("Proceed to payment");
    navigate("/payment-success"); // Adjust the route as necessary
  };

  if (cartItems.length === 0) {
    return <div>Your cart is empty.</div>;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////payment CHECKOUT FORM

  const CheckoutForm = (totalAmount) => {
    const stripe = useStripe();
    const elements = useElements();

    const handlePaymentSuccess = async () => {
      // Retrieve the purchased service data from local storage
      const purchasedService = JSON.parse(localStorage.getItem("purchasedService"));
      const user = auth.currentUser;
    
      if (user && purchasedService && purchasedService.id) {
        try {
          // Update only the 'service' field with the 'id' of the purchased service in the user's document
          const userRef = doc(db, "users", user.uid);
          await setDoc(
            userRef,
            { service: purchasedService.id }, // Only updating the 'service' field with the service id
            { merge: true }
          );
          console.log("User's purchased service ID updated successfully.");
    
          // Remove the purchased service from local storage after updating Firebase
          localStorage.removeItem("purchasedService");
        } catch (error) {
          console.error("Error updating user's purchased service ID:", error);
        }
      }
    };
    
    const handleSubmit = async (event) => {
      event.preventDefault(); // Prevent the form from submitting in the traditional way

      // Email validation regex pattern
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Validate cardholder's name
      if (!cardholderName) {
        alert("Please fill in your name.");
        return;
      }

      // Validate email address format
      if (!emailAddress || !emailPattern.test(emailAddress)) {
        alert("Please enter a valid email address.");
        return;
      }

      if (!stripe || !elements) {
        console.log("Stripe has not loaded yet.");
        return;
      }
    
      let clientSecret, paymentIntentId;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/create-payment-intent`,
          {
            amount: total, // Send the correct total calculated with selectedPrice
          }
        );
        clientSecret = response.data.clientSecret;
        paymentIntentId = response.data.paymentIntentId;
      } catch (error) {
        console.error("Error creating PaymentIntent:", error);
        return;
      }

  // Confirm the payment
  const cardElement = elements.getElement(CardElement);
  const result = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: cardElement,
      billing_details: {
        name: cardholderName,
        email: emailAddress,
      },
    },
  });

  if (result.error) {
    console.log(result.error.message);
  } else {
    if (result.paymentIntent.status === "succeeded") {
      console.log("Payment succeeded");
      await handlePaymentSuccess(); // Update Firebase and clear local storage
      navigate(`/paymentSuccess`); // Redirect to success page
    } else {
      // Handle other payment statuses accordingly
    }
  }
};

    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          iconColor: "#666EE8",
          color: "black",
          fontWeight: 300,
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSize: "18px",
          "::placeholder": {
            color: "#CFD7E0",
          },
          ":-webkit-autofill": {
            color: "#fce883",
          },
          ":-webkit-autofill": {
            color: "#fce883",
          },
          "input:-webkit-autofill": {
            color: "#fce883",
          },
        },
      },
      hidePostalCode: true,
    };

    return (
      <div className="stripe-card-container">
        <form onSubmit={handleSubmit}>
          <div className="display-card">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <div>
              <button
                type="submit"
                className="payment-button-checkout"
                disabled={!stripe}
              >
                <span className="payment-info-checkout">Pay</span>
                <span className="total-price"> €{total.toFixed(2)}</span>
                <svg
                  className="lock-icon-checkout"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="16"
                  height="16"
                >
                  <path d="M12 2C9.243 2 7 4.243 7 7v3H5c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-2V7c0-2.757-2.243-5-5-5zm0 2c1.654 0 3 1.346 3 3v3H9V7c0-1.654 1.346-3 3-3zm-7 8h14v9H5v-9z" />
                  <path d="M12 10c.552 0 1 .449 1 1v4c0 .551-.448 1-1 1s-1-.449-1-1v-4c0-.551.448-1 1-1zm0-1c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2s2-.897 2-2v-4c0-1.103-.897-2-2-2z" />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div>
      <h1>Checkout</h1>
      {cartItems.map((item, index) => (
        <div key={index}>
          <h2>{item.serviceName} ({item.quantity || 1})</h2>
          <p>Price: €{item.selectedPrice ? item.selectedPrice.toFixed(2) : "N/A"} each</p>
        </div>
      ))}
      <h3>Total: €{total.toFixed(2)}</h3>
      <button onClick={handlePayment}>Proceed to Payment</button>
      <div className="box-pay">
        Enter Details:
        <div
          style={{
            border: "2px solid black",
            borderRadius: "5px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginLeft: "18px",
              marginTop: "14px",
            }}
          >
            Email Address
            <input
              className="email-address-name"
              style={{ fontSize: "14px" }}
              type="text"
              id="email-address"
              name="emailAddress"
              value={emailAddress}
              placeholder="Enter your email"
              onChange={(e) => setEmailAddress(e.target.value)}
              required
            />
            Name
            <input
              className="email-address-name"
              style={{ fontSize: "14px" }}
              type="text"
              id="cardholder-name"
              name="cardholderName"
              value={cardholderName}
              placeholder="Enter your name"
              onChange={(e) => setCardholderName(e.target.value)}
              required
            />
            <Elements stripe={stripePromise}>
              <div style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                {" "}
                Pay with Card
              </div>
              <CheckoutForm />
              <div className="other-options">Other options</div>
              <PaymentMethodMessagingElement
                options={{
                  amount: Math.round(total * 100), // Convert total to cents
                  currency: "EUR",
                  paymentMethodTypes: ["klarna"],
                  countryCode: "IT",
                }}
              />
            </Elements>
          </div>
        </div>
      </div>
    </div>
  );
}
