import React, { useState, useEffect } from "react";
import { auth, db } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  collection,
  getDocs,
  getDoc,
} from "firebase/firestore";
import Users from "./users.js";
import { useAuth } from "../../src/components/auth-email.js";
import ToggleSwitchSearch from "../components/togglesearch.js";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  LineChart,
} from "recharts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//../src/components/userPrompt.js";
import thumbup from "..//../src/thumbup.svg";
import thumbdown from "..//../src/thumbdown.svg";
import rightarrow from "..//../src/right_arrow.svg";
import { Spinner } from "react-bootstrap";

function IncentiveSearch() {
  const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [companyServices, setCompanyServices] = useState("");
  const [currentUserID, setCurrentUserID] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState(0);
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [documents, setDocuments] = useState([]);
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState("");
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shouldRerender, setShouldRerender] = useState(false);
  const requiredServices = ["jQnbH5O53pBykJ6vFI9U", "0UuzYZolWjBtFqHCQqNt"];
  const [showFiltersGroups, setShowFiltersGroups] = useState(false);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //// GET USER INFO

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (user) => {
    if (user && user.email) {
      const usersCollectionRef = collection(db, "users");
      const q = query(usersCollectionRef, where("email", "==", user.email));
      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          //console.log("Fetched user data:", userData);
          setCompanyServices(userData.service || []);

          const userDoc = querySnapshot.docs[0];
          setCurrentUserID(userDoc.id); // Set currentUserID to the user's document ID
          //console.log("Setting currentUserID to:", userDoc.id);

          // Initial call to fetch incentives using currentUserID
          fetchIncentivesData(userDoc.id, filters);
          setFirstName(userData.firstName);
          setCurrentUserID(userDoc.id);
          setCompanyName(userData.companyName);
          setCompanySize(userData.companySize);
          setCompanyEmployees(userData.companyEmployees);
          setCompanyTurnover(userData.companyTurnover);
          setAtecoL3(userData.companyAteco);
          setRegione(userData.regione);
          

          // Set other user-related state variables as needed
        } else {
          console.log("No user data found for email:", user.email);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  
  // Run on currentUser change
  useEffect(() => {
    if (currentUser) {
      fetchUserData(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (companyServices && companyServices.length > 0) {
      const hasRequiredService = requiredServices.some((service) =>
        companyServices.includes(service)
      );
      setIsFilterEnabled(hasRequiredService);
      //console.log("isFilterEnabled:", hasRequiredService);
    } else {
      setIsFilterEnabled(false);
    }
  }, [companyServices]);


/////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////
// FETCH DATA AND APPLY FILTERS

const [filters, setFilters] = useState({
  CW: null,
  Agevolazione: null,
  Fondo_perduto: null,
  Capitale_di_rischio: null,
  Prestito: null,
  Interventi_garanzia: null,
  Anticipo_rimborsabile: null,
  hyperFilter: null,
  strategy: null,
  thumb: null,
});

const [filteredCount, setFilteredCount] = useState(0);

// Function to handle filter changes
const handleFilterChange = (field, value) => {
  setFilters((prevFilters) => {
    const newFilters = { ...prevFilters, [field]: value ? "Y" : null };
    fetchIncentivesData(currentUserID, newFilters); // Use currentUserID
    return newFilters;
  });
};

// Fetch incentives when component mounts or filters change
useEffect(() => {
  if (currentUserID) {
    setLoading(true);
    fetchIncentivesData(currentUserID, filters);
  }
}, [currentUserID, filters]);

// Fetch user data when currentUser changes
useEffect(() => {
  if (currentUser) {
    //console.log("Current user changed, fetching user data.");
    fetchUserData(currentUser);
  }
}, [currentUser]);

// Updated fetchIncentivesData function
const fetchIncentivesData = async (userId, filters = {}) => {
  try {
    //console.log("fetchIncentivesData called with userId:", userId);
    //console.log("Current filters:", filters);

    const incentivesDocRef = doc(db, "filteredData", userId);
    const incentivesDoc = await getDoc(incentivesDocRef);

    if (incentivesDoc.exists()) {
      const data = incentivesDoc.data();
      let incentivesData = Array.isArray(data.incentives) ? data.incentives : [];

      //console.log("Incentives data from filteredData (before sanitizing):", incentivesData);

      // Sanitize NaN values
      incentivesData = incentivesData.map(sanitizeIncentiveData);

      //console.log("Incentives data after sanitizing NaN values:", incentivesData);

      const matchingIncentives = incentivesData.filter((incentive) => {
        let matches = true;

        //console.log("Checking incentive:", incentive);

        // Only apply non-null filters
        for (const [filterField, filterValue] of Object.entries(filters)) {
          if (filterValue) {
            const incentiveField = String(incentive[filterField] || "N");
            const expectedValue = String(filterValue);

            //console.log(`Comparing ${filterField} - Incentive value: ${incentiveField}, Filter value: ${expectedValue}`);

            if (incentiveField !== expectedValue) {
              matches = false;
              break;
            }
          }
        }

        return matches;
      });

      //console.log("Matching incentives after filtering:", matchingIncentives);

      const incentiveIds = matchingIncentives.map((item) => item.ID_Incentivo);

      const incentiveDetails = await Promise.all(
        incentiveIds.map(async (id) => {
          const incentiveRef = doc(db, "incentivi", id);
          const incentiveDoc = await getDoc(incentiveRef);

          if (incentiveDoc.exists()) {
            return {
              ...incentiveDoc.data(),
              ID_Incentivo: id,
              filtered: 1,
            };
          }
          return null;
        })
      );

      const mappedIncentives = incentiveDetails.filter(Boolean);

      //console.log("Mapped incentives data before setting state:", mappedIncentives);

      setIncentives(mappedIncentives);
      setFilteredCount(mappedIncentives.length);
    } else {
      console.log("No incentives data found for this user.");
      setIncentives([]);
    }
  } catch (error) {
    console.error("Error fetching incentives data:", error);
    setIncentives([]);
  } finally {
    setLoading(false);
  }
};

// Helper function to sanitize NaN values in an incentive
const sanitizeIncentiveData = (incentive) => {
  const sanitizedIncentive = { ...incentive };
  for (const key in sanitizedIncentive) {
    if (typeof sanitizedIncentive[key] === "number" && isNaN(sanitizedIncentive[key])) {
      sanitizedIncentive[key] = "N"; // Replace NaN with "N" or other default value
    } else if (sanitizedIncentive[key] == null) {
      sanitizedIncentive[key] = "N"; // Replace null or undefined with "N"
    }
  }
  return sanitizedIncentive;
};

  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////// FORMATING
  

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end

      // Remove everything before and including the first colon on the first line
      desc = desc.replace(/^[^:]*:\s*/, "");

      // Normalize new lines and handle the initial bullet point
      let lines = desc.split(/\n+/); // Split the description into lines
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim(); // Trim each line to remove leading/trailing whitespace

        // Skip empty lines to avoid adding extra spaces
        if (line === "") {
          return filteredLines;
        }

        // Handle numbered lines to not start with a bullet
        if (/^\d+\./.test(line)) {
          filteredLines.push(line); // Return the line as is if it starts with a number and a period
        } else if (index > 0 && filteredLines.length > 0) {
          // Apply bullets to lines that are not the first line and not after an empty line
          filteredLines.push("• " + line);
        } else {
          filteredLines.push(line); // First line or any line not fitting the above criteria gets no bullet
        }

        return filteredLines;
      }, []);

      // Join the lines back together, inserting a newline between each
      return lines.join("\n");
    }
    return "No description available"; // Return a default message if no description is provided
  };

  const getCategoryClass = (category) => {
    const categoryClasses = {
      "Crisi d'impresa": "crisi-impresa",
      Digitalizzazione: "digitalizzazione",
      "Imprenditoria femminile": "imprenditoria-femminile",
      "Innovazione e ricerca": "Innovazione-ricerca",
      "Sostegno investimenti": "Sostegno-investimenti",
      "Sostegno liquidità": "Sostegno-liquid",
      "Start up/Sviluppo d'impresa": "Startup-impresa",
      "Crisi d'impresa": "Crisi-impresa",
      "Inclusione sociale": "Inclusione-sociale",
      "Imprenditoria giovanile": "Imprenditoria-giovanile",
      Internazionalizzazione: "Internazionalizzazione",
      "Transizione ecologica": "Transizione-ecologica",
      // Add more mappings here...
    };
    return categoryClasses[category] || "default-category"; // Fallback class
  };

  const formatDate = (dateString) => {
    return dateString
      ? dateString.split("T")[0]
      : "Aperto fino a nuova communicazione"; // Default to 'N/A' if dateString is undefined
  };

  const placeholders = [
    "SMART&START",
    "PNRR",
    "CREDITO",
    "INTERNAZIONALE",
    "INFRASTRUTTURE",
    "TASSO ZERO",
    "AGEVOLAZIONI",
  ];
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      // Update the placeholder text by cycling through the placeholders array
      currentIndex = (currentIndex + 1) % placeholders.length;
      setCurrentPlaceholder(placeholders[currentIndex]);
    }, 1000); // Change placeholder every 2000 milliseconds (2 seconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  //////////////////////////////////////////////////////////////////////////////
  /////////////////
  /// Nav to ThreadUnic
  const navigate = useNavigate();
  const [threadId, setThreadId] = useState("");
  const [currentThreadId, setCurrentThreadId] = useState(null);

  const startNewConversation = async (
    atecoL3,
    regione,
    assistant,
    incentiveId,
    firstName,
    companyName,
    companySize,
    companyEmployees,
    companyTurnover
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, e questo incentivo buona per nostra azienda? Io sono ${firstName} e l'azienda si chiama ${companyName}. Siamo in ${regione} con ateco: ${atecoL3}. Siamo un ${companySize} con ${companyEmployees} dipendenti e turnover annuale di €${companyTurnover}.`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userMessage }),
      });
      //console.log("API Base URL:", API_BASE_URL);
      //console.log("firstName:", firstName);
      //console.log("companyName:", companyName);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("Response data:", data); // Log the entire response data
      localStorage.setItem("currentThreadId", data.thread_id);
      //console.log("Assistant parameter:", assistant);
      //console.log("Stored thread_id in localStorage:", data.thread_id);

      if (data.thread_id && assistant) {
        //console.log("New thread ID:", data.thread_id, "assistant:", assistant);
        const title = `Discussion on ATECO ${atecoL3} in ${regione}`;
        window.scrollTo(0, 0);
        // Navigate to the threadUnic page with the thread ID and assistant ID in the URL
        navigate(`/threadUnic/${assistant}/${data.thread_id}/${incentiveId}`, {
          state: { title: title },
        });
      } else {
        console.error(
          "Thread ID or Assistant ID was not returned from the server."
        );
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const extractBase = (ateco) => {
    // Regex to capture two digits before and one or two digits after the decimal point
    const numericalPartMatch = ateco.match(/^(\d{2}\.\d{1,2})/);
    let numericalPart = "";

    if (numericalPartMatch) {
      numericalPart = numericalPartMatch[1];
      const decimalParts = numericalPart.split(".");

      // Ensure two decimal places by padding with a zero if necessary
      if (decimalParts[1].length === 1) {
        numericalPart = `${decimalParts[0]}.${decimalParts[1]}0`;
      }
    }

    return numericalPart;
  };

  const updateThumbs = async (incentiveId, thumbsValue) => {
    if (!currentUser || !currentUser.uid) {
      console.error("User is not authenticated or UID is missing");
      return; // Do nothing if user is not authenticated
    }

    /* console.log("Sending thumbs update:", {
      userId: currentUser.uid, // Use currentUser.uid instead of currentUser.id
      incentiveId,
      thumbsValue,
    }); */ // Log the values being sent

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/updatethumbs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: currentUser.uid, // Ensure this is the actual user UID
          incentiveId, // This should be the actual incentive ID
          thumbsValue, // This should be 'Y' or 'N'
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Update successful:", data.message);
      } else {
        console.error("Error updating thumbs:", data.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="searchy-container">
      {!isAuthenticated && <UserPromptModal />}
      <div className="searchy-containered">
        <div>
          <div className="agent2-containers">
            <div className="incentivy-container">
              <h2
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "22px",
                  lineHeight: "2.5rem",
                  margin: "0 auto",
                  letterSpacing: "2px",
                  fontFamily: "sans-serif",
                  maxWidth: "400px",
                }}
              >
                {firstName
                  ? capitalizeFirstLetter(firstName)
                  : "No user data available or user not signed in."}
                , filtrando per Regione{" "}
                <span style={{ color: "#FF5758" }}>({regione})</span>, Ateco{" "}
                <span style={{ color: "#FF5758" }}>
                  ({extractBase(atecoL3)})
                </span>{" "}
                e Dimensione{" "}
                <span style={{ color: "#FF5758" }}>({companySize})</span>: ci
                sono <span style={{ color: "#FF5758" }}>{filteredCount}</span>{" "}
                potenziali incentivi per{" "}
                {companyName
                  ? companyName
                  : "No user data available or user not signed in."}
                <span
                  style={{
                    color: "white",
                    fontSize: "20px",
                    lineHeight: "1.5rem",
                    fontWeight: "normal", // Ensures the text is not bold
                    display: "block",
                    maxWidth: "450px", // Sets maximum width for the text
                    margin: "0 auto", // Centers the block if needed
                  }}
                ></span>
              </h2>
              <div
                style={{ fontSize: "20px", color: "white", marginTop: "25px" }}
              ></div>
              <button
                className="button-searchi"
                onClick={() => setShowFiltersGroups((prev) => !prev)}
              >
                {showFiltersGroups ? "Nascondi Filtri" : "Mostra Filtri"}
              </button>
             {/*  <button className="button-searchi" onClick={resetFilters}>
                Reimposta Filtri
              </button> */}
              {showFiltersGroups && (
                <div
                  className="filters-searchxy"
                  style={{ paddingTop: "20px" }}
                >
                  {/* Upgrade button outside the restricted filters box */}
                  {!isFilterEnabled && (
                    <button
                      className="filter-services-box"
                      onClick={() => navigate("/service-offers")}
                    >
                      UPGRADE
                    </button>
                  )}
                  <div className="filters-searchx">
                    {/* Conditional grey box for the first two filters */}
                    <div className="restricted-filters-services">
                      Personalizzato
                      <div className="toggle-switch-container-paid">
                        <ToggleSwitchSearch
                          label="HyperFilter"
                          onChange={(value) =>
                            handleFilterChange("hyperFilter", value)
                          }
                          disabled={!isFilterEnabled}
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            Basato su AI. Include comune
                          </span>
                        </span>
                      </div>
                      <div className="toggle-switch-container-paid">
                        <ToggleSwitchSearch
                          label="Obiettivi"
                          onChange={(value) =>
                            handleFilterChange("strategy", value)
                          }
                          disabled={!isFilterEnabled}
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            In base ai tuoi obiettivi
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="filters-wrapper">
                      <div className="toggle-switch-container">
                        <ToggleSwitchSearch
                          label="Preferiti"
                          onChange={(value) =>
                            handleFilterChange("thumb", value)
                          }
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            I tuoi preferiti
                          </span>
                        </span>
                      </div>

                      <div className="toggle-switch-container">
                        <ToggleSwitchSearch
                          label="Settimana Corrente"
                          onChange={(value) => handleFilterChange("CW", value)}
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            Novità questa settimana
                          </span>
                        </span>
                      </div>

                      <div className="toggle-switch-container">
                        <ToggleSwitchSearch
                          label="Agevolazione"
                          onChange={(value) =>
                            handleFilterChange("Agevolazione", value)
                          }
                        />
                        <span className="info-icon">
                          i<span className="tooltip-texty">Sgravi fiscali</span>
                        </span>
                      </div>

                      <div className="toggle-switch-container">
                        <ToggleSwitchSearch
                          label="Fondo Perduto"
                          onChange={(value) =>
                            handleFilterChange("Fondo_perduto", value)
                          }
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            Non richiedono il rimborso
                          </span>
                        </span>
                      </div>

                      <div className="toggle-switch-container">
                        <ToggleSwitchSearch
                          label="Capitale di rischio"
                          onChange={(value) =>
                            handleFilterChange("Capitale_di_rischio", value)
                          }
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            Investimento in equity
                          </span>
                        </span>
                      </div>

                      <div className="toggle-switch-container">
                        <ToggleSwitchSearch
                          label="Prestito"
                          onChange={(value) =>
                            handleFilterChange("Prestito", value)
                          }
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            Devono essere restituiti
                          </span>
                        </span>
                      </div>

                      <div className="toggle-switch-container">
                        <ToggleSwitchSearch
                          label="Interventi Garanzia"
                          onChange={(value) =>
                            handleFilterChange("Interventi_garanzia", value)
                          }
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            Facilitare l'accesso al credito
                          </span>
                        </span>
                      </div>

                      <div className="toggle-switch-container">
                        <ToggleSwitchSearch
                          label="Anticipo rimborsabile"
                          onChange={(value) =>
                            handleFilterChange("Anticipo_rimborsabile", value)
                          }
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            Ricevere un'importo di denaro in anticipo, che dovrà
                            essere successivamente rimborsato
                          </span>
                        </span>
                      </div>
                    </div>

                  
                  </div>
                </div>
              )}
             
            </div>
          </div>
          <div
            style={{
              margin: "7px",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
                fontSize: "16px",
              }}
            >
             
              <div>
                <div style={{ textAlign: "left", fontSize: "16px" }}>
                  <div>
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    {/* {responseMessage && formatResponseMessage(responseMessage)} */}
                  </div>
                </div>
                <div>
                  <div
                    className="incentivy-container"
                    style={{ background: "#092f7a" }}
                  >
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    <h1
                      style={{
                        marginTop: "20px",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Incentivi Dedicati
                    </h1>
                    {loading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{
                          display: "block",
                          margin: "auto",
                          color: "white",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : incentives.length > 0 ? (
                      <ul style={{ padding: "10px", listStyleType: "none" }}>
                    {incentives
                      .filter((doc) => doc.filtered === 1) // Filter out items without `filtered: 1`
                      .map((doc, index) => (
                        <li
                          key={index}
                          style={{
                            marginBottom: "20px",
                            border: "1px solid #ccc",
                            padding: "8px",
                            borderRadius: "8px",
                            boxShadow:
                              "0 4px 6px rgba(0,0,0,0.1), 5px 5px 5px #FF5758",
                            backgroundColor: "#fff", // adds a clean white background
                            lineHeight: "2rem",
                            fontSize: "20px",
                            position: "relative", // Needed for absolute positioning of the arrow
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "2px",
                              right: "10px",
                              display: "flex",
                              alignItems: "center",
                              margin: "10px 0 10px 0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <button
                                onClick={() =>
                                  updateThumbs(doc.ID_Incentivo, "Y")
                                }
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  backgroundColor: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  fontSize: "25px",
                                }}
                              >
                                <img
                                  src={thumbup} // Path to your SVG file
                                  alt="Thumbs Up"
                                  style={{ width: "30px", height: "30px" }} // Adjust width/height
                                />
                              </button>
                              <button
                                onClick={() =>
                                  updateThumbs(doc.ID_Incentivo, "N")
                                }
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  fontSize: "25px",
                                }}
                              >
                                <img
                                  src={thumbdown} // Path to your SVG file
                                  alt="Thumbs Down"
                                  style={{ width: "30px", height: "30px" }} // Adjust width/height
                                />
                              </button>
                            </div>
                            <button
                              className="button-stylish"
                              onClick={() => {
                                //console.log("Document data:", doc); // Log the entire document
                                const assistant = doc.AssistantID; // Rename AssistantID to assistant
                                startNewConversation(
                                  atecoL3,
                                  regione,
                                  assistant,
                                  doc.ID_Incentivo,
                                  firstName,
                                  companyName,
                                  companySize,
                                  companyEmployees,
                                  companyTurnover
                                );
                              }}
                              style={{ cursor: "pointer", fontSize: "35px" }}
                            >
                              <img
                                src={rightarrow} // Path to your SVG file
                                alt="navigate to criteria page"
                                style={{ width: "30px", height: "30px" }} // Adjust width/height
                              />
                            </button>
                          </div>
                          {/* <img
                          className="image-article-searchid"
                          src={doc.urlImage}
                          alt={`Image of ${doc.Titolo}`}
                        /> */}
                          <h2 style={{ marginTop: "60px" }}>{doc.Titolo}</h2>
                          <strong>
                            {doc.KeyBenefit} <br />
                          </strong>
                          <div style={{ marginTop: "10px" }}>
                            <strong>Apertura:</strong>{" "}
                            {formatDate(doc.Data_apertura)}{" "}
                            <strong>Chiusura:</strong>{" "}
                            {formatDate(doc.Data_chiusura)}
                          </div>

                          <div>
                            {doc.Obiettivo_Finalita.map((goal) => (
                              <span
                                key={goal}
                                className={`category-box ${getCategoryClass(
                                  goal
                                )}`}
                                style={{ marginTop: "10px" }}
                              >
                                {goal}
                              </span>
                            ))}
                          </div>
                          <pre className="description-box">
                            {formatDescription(doc.Summary)}
                          </pre>
                        </li>
                      ))}
                  </ul>
                    ) : (
                      <p>Loading, Aspeta...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncentiveSearch;
